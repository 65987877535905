<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <form>
          <!-- <b-form-group
              id="input-group-message"
              label="Pesan"
              label-for="input-message"
            >
              <b-form-textarea
                id="input-message"
                v-model="form.message"
                placeholder="Pesan"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.message }}</small>
            </b-form-group> -->
          <div class="wrapper-emoji">
            <textarea
              class="regular-input"
              v-model="form.message"
              :placeholder="'Apa yang kamu pikirkan, ' + userData.name + '?'"
            ></textarea>

            <emoji-picker @emoji="append" :search="search">
              <div
                class="emoji-invoker"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <svg
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                  />
                </svg>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <!-- <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }"> -->
                <div class="emoji-picker emoji-picker-position">
                  <div class="emoji-picker__search">
                    <input type="text" v-model="search" v-focus />
                  </div>
                  <div>
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </div>
        </form>
      </div>
      <div class="col-md-2">
        <b-button
          type="submit"
          @click.stop.prevent="formValidation()"
          variant="primary"
          >Post</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import EmojiPicker from "vue-emoji-picker";

export default {
  props: {
    classGroupId: Number,
    purposeModal: String,
    data: Object,
  },
  components: {
    EmojiPicker,
  },
  data() {
    return {
      search: "",
      form: {
        class_group_id: "",
        parent_id: "",
        user_id: "",
        message_to: "",
        message: "",
        like: "",
      },
      error: {
        class_group_id: "",
        parent_id: "",
        user_id: "",
        message_to: "",
        message: "",
        like: "",
      },
      userData: getUser(),
    };
  },
  methods: {
    setEdit() {
      if (this.purposeModal == "update") {
        //("true", this.form);
        this.form = this.data;
      } else {
        //("false", this.purposeModal);
      }
    },
    append(emoji) {
      this.form.message += emoji;
    },
    cancelAdd() {
      this.$emit("cancelAdd");
    },
    formValidation() {
      if (this.form.message == "") {
        this.error.message = "Kolom keterangan tidak boleh kosong";
      } else {
        this.formOnsubmit();
      }
    },
    async formOnsubmit() {
      this.form.parent_id = 0;
      this.form.user_id = getUser().id;
      this.form.message_to = -1;
      this.form.class_group_id = this.classGroupId;
      let route = "";
      if (this.purposeModal == "add") {
        route = "api/class-group-conversations";
      } else if (this.purposeModal == "update") {
        route = `api/class-group-conversations/${this.form.id}`;
      }
      // Make Request
      let response = await module.submit(this.form, route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$emit("successAdd");
        this.form.message = "";
        this.form["_method"] = "post";
        // this.$router.push("/masters/formats");
      }
    },
  },
  mounted() {
    this.setEdit();
  },
  watch: {
    classGroupId: function (newVal, oldVal) {
      this.form.class_group_id = this.classGroupId;
    },
    data: function (newVal, oldVal) {
      //("data changed", newVal);
      this.setEdit();
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style scoped>
/* ::placeholder { 
  color: black;
  opacity: 1; 
} */

  .wrapper-emoji {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 450%;
  height: 4rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  left: 430%;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

/* my style */
.emoji-picker-position {
  /* style="position: absolute; bottom: 70px; left: 550px;" */
  position: absolute;
  top: 60px;
  left: 550px;
}

/* mobile */
@media (max-width: 767.98px) {
  .regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 160%;
    height: 4rem;
    outline: none;
  }

  .emoji-invoker {
    position: absolute;
    top: 0.5rem;
    left: 140%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  .emoji-picker-position {
    position: absolute;
    bottom: 66px;
    left: 60px;
  }
}
</style>