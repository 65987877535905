<template>
  <!-- <v-app> -->
    <div class="card card-custom shadow-none" v-if="my_classrooms.length > 0">
      <!-- <div class="card-header border-0"> -->
      <!-- </div> -->
      <div class="card-body py-2 px-1">
        <!-- <h4 class="font-weight-bolder text-dark ml-3">
          Kelas Saya
        </h4> -->
        <v-slide-group
          v-model="model"
          class="pa-0"
          active-class="success"
          show-arrows
        >
          <v-slide-item v-for="(item, i) in my_classrooms" :key="i" v-slot="{ active, toggle }" @change="$router.push({path: '/classrooms/detail/' + item.id})">
            <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              :color="active ? undefined : 'grey lighten-1'"
              class="ma-3 card-my-classroom"
              height="100"
              width="100"
              @click="toggle"
            >
              <!-- :style="'background-image: url('+ item.image_thumbnail +'); opacity: 0.6;'" -->

              <v-img
                :src="item.image_thumbnail"
                height="100"
              >
                <v-row class="fill-height content-card-my-classroom pa-3" align="center" justify="center">
                    <div class="text-center text-white bg-primary w-100">
                      <h6>{{item.name}}</h6>
                    </div>
                </v-row>
              </v-img>
            </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  <!-- </v-app> -->
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "widget-2",
  data() {
    return {
      model: null,
      my_classrooms: [],
      transparent: 'rgba(255, 255, 255, 0)',
    };
  },
  methods: {
    async getMyClassroom() {
      let filterParams = ``;
      let response = await module.paginate(
        `api/classrooms/my-classrooms/${getUser().id}`,
      );

      this.my_classrooms = response.data;
    },
  },
  mounted() {
    this.getMyClassroom()
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>

<style scoped>
/* .v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
 } */
</style>
