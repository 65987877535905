<template>
  <div>
    <div class="container" :class="place == 'dashboard' ? 'px-0' : ''">
      <div class="row mt-3">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-3">
                  <treeselect
                    v-model="filter.semester_id"
                    :multiple="false"
                    placeholder="Pilih Semester"
                    :options="semesters"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="750"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props:{
    place: {
      type: String,
      default: 'blankboard'
    }
  },
  data() {
    return {
      currentUser: getUser(),
      series: [
        {
          name: "",
          data: [0],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 850,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "",
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      auditChartData: [],
      categories: [],
      semesters: [],
      filter: {
        semester_id: "",
      },
    };
  },
  methods: {
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters-no-auth");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters-no-auth/now");
      // If Data Not Found
      if (response != null) {
        this.filter.semester_id = response.id;
      } else {
        console.log("null");
      }

      this.setGraphReport()
      console.log("run sermester active");
    },
    async setGraphReport() {
      let filterParams = `semester_id=${this.filter.semester_id}`;

      if(this.place == 'dashboard'){
        this.chartOptions.chart.height = 200

        if(this.currentUser.role_id == 1 || this.currentUser.role_id == 4){
          filterParams += `&teacher_id=${this.currentUser.id}`
        }
      }

      let response = await module.getReports(
        `api/graph-report-no-auth?${filterParams}`
      );
      // If Data Not Found
      if (response == null) {
      } else {
        console.log("response in here", response);
        this.series = response.series;
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: response.categories,
            },
          },
        };
      }
    },
  },
  mounted() {
    // this.setGraphReport();
    this.getSemesterOption();
    this.getSemesterActive();
  },
};
</script>

<style>
</style>