<template>
  <div v-if="dashboard && dataLoaded">
    <v-app>
      <!-- <div class="card">
        <div class="card-body p-0"> -->
          <!-- <b-alert
            show
            variant="light"
            class="
              alert alert-custom alert-white alert-shadow
              fade
              show
              gutter-b
              py-2
            "
            v-if="
              capture_status == false &&
              is_presence_today == true &&
              presence_today_status == false &&
              userData.role_id == 2
            "
          >
            <div class="col-md-2 p-0 mr-4">
              <vue-web-cam
                ref="webcam"
                :device-id="deviceId"
                width="100%"
                height="100%"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @cameras="onCameras"
                @camera-change="onCameraChange"
                v-if="capture_status == false && is_presence_today == true"
              />
              <img
                :src="baseImg"
                height="100%"
                width="100%"
                v-if="capture_status"
              />
            </div>
            <div class="alert-text" v-if="presence_today_status == false">
              <b>{{ date }}, {{ time }}</b>
            </div>
            <div class="mt-md-9 mt-sm-4" v-if="presence_today_status == true">
              <span class="text-h5"
                >Kamu sudah melakukan presensi hari ini pada
                {{ absentTime }}</span
              >
            </div>
            <b-button
              squared
              variant="primary"
              class="h-50 mb-auto mt-auto mr-2"
              @click.stop.prevent="formOnsubmitPresence"
              v-if="capture_status == false"
              ><i class="fas fa-camera"></i> Hadir</b-button
            >
            <b-button
              squared
              variant="danger"
              class="h-50 mb-auto mt-auto"
              @click.stop.prevent="showModalAbsent"
              v-if="capture_status == false"
              >Izin tidak hadir</b-button
            >
          </b-alert> -->
          <v-alert
            prominent
            type="info"
            color="#1BC5BD"
            v-if="presence_today_status == true && is_presence == 2"
          >
            <v-row align="center">
              <v-col class="grow">
                Kamu telah berhasil melakukan presensi hari ini pada
                {{ absentTime }}
              </v-col>
              <v-col class="shrink">
                <!-- <v-btn
                  @click="$router.push(`/presence/detail/${presence_today.id}`)"
                  >Detail</v-btn
                > -->
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            prominent
            type="info"
            color="info"
            v-if="presence_today_status == true && is_presence == 1"
          >
            <v-row align="center">
              <v-col class="grow">
                Kamu izin untuk tidak sekolah hari ini
              </v-col>
              <v-col class="shrink">
                <!-- <v-btn
                  @click="$router.push(`/presence/detail/${presence_today.id}`)"
                  >Detail</v-btn
                > -->
              </v-col>
            </v-row>
          </v-alert>
          <div class="row">
            <div class="col-xl-4 p-2">
              <ClassworkToday />
            </div>
            <div class="col-xl-4 p-2">
              <ClassworkTomorrow />
            </div>
            <div class="col-xl-4 p-2">
              <Announcement />
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-12 p-2">
              <Forum />
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-12 p-2">
              <Calendar></Calendar>
            </div>
          </div>
          <div class="row" v-if="currentUser.role_id == 1 || currentUser.role_id == 4">
            <div class="col-xxl-12 p-2">
              <GraphReport :place="'dashboard'" />
            </div>
          </div>
        <!-- </div>
      </div> -->
    </v-app>
    <ModalAbsent
      @cancelAbsent="$bvModal.hide('modal-absent')"
      @successAbsent="successAbsent"
      :route="'api/student-presences'"
      purpose="add"
      :data="update_absent"
    />
    <!-- <ModalAbsent
      @cancelAbsent="$bvModal.hide('modal-absent')"
      @successAbsent="successAbsent"
      :data="update_absent"
      :route="routeAbsent"
      :purpose="purposeAbsent"
    /> -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClassworkToday from "@/view/components/dashboard/ClassworkToday.vue";
import ClassworkTomorrow from "@/view/components/dashboard/ClassworkTomorrow.vue";
import Announcement from "@/view/components/dashboard/Announcement.vue";
import MyClassroom from "@/view/components/dashboard/MyClassroom.vue";
import Forum from "@/view/components/dashboard/Forum.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import Calendar from "@/view/components/general/MonthCalendar.vue";
import { WebCam } from "vue-web-cam";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import ModalAbsent from "@/view/components/general/ModalAbsent.vue";
import Calendar from "@/view/pages/masters/rooms/CalendarDashboard.vue";
import GraphReport from "@/view/pages/graph-report/GraphReport.vue";

export default {
  name: "dashboard",
  components: {
    "vue-web-cam": WebCam,
    ClassworkToday,
    ClassworkTomorrow,
    Announcement,
    MyClassroom,
    Forum,
    StatsWidget7,
    StatsWidget12,
    ListWidget8,
    AdvancedTableWidget2,
    Calendar,
    ModalAbsent,
    GraphReport,
  },
  data() {
    return {
      currentUser: getUser(),
      update_absent: {},
      // camera
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      baseImg: null,
      // date&time
      time: "",
      date: "",
      updateTimeStatus: true,
      // data absen hari ini
      presence_today: [],
      // user
      userData: getUser(),
      form: {
        student_id: getUser().id,
        presence_type_id: 0,
        date: "",
        photo: "",
        late: "",
        student_name: getUser().name,
        is_presence: 2,
      },
      // other
      capture_status: false,
      presence_today_status: false,
      is_presence_today: true,
      absentTime: "",
      is_presence: "",
      dataLoaded: false,
      // access
      dashboard: false,
      calendar: false
    };
  },
  methods: {
    showModalAbsent() {
      this.$bvModal.show("modal-absent");
    },

    successAbsent(response) {
      this.presence_today_status = true;
      this.absentTime = response.success.item.original.date.split(" ")[1];
      this.presence_today = response.success.item.original;
      this.is_presence = 1;
      this.$bvModal.hide("modal-absent");
    },

    async checkPresenceToday() {
      let currentTime = new Date().toISOString().substring(0, 10);
      let filterParams = `presence_today=${currentTime}&student_id=${
        getUser().id
      }`;
      let response = await module.paginate(
        `api/student-presences`,
        `?${filterParams}`
      );

      if (response.data.length > 0) {
        this.presence_today_status = true;
        this.presence_today = response.data[0];
        this.baseImg = response.data[0].photo;
        this.capture_status = true;
        this.absentTime = response.data[0].date.split(" ")[1];
        this.is_presence = response.data[0].is_presence;
      }
    },

    async checkIsPresenceToday() {
      let currentTime = new Date().toISOString().substring(0, 10);
      let filterParams = `is_presence_today=${currentTime}`;
      let response = await module.paginate(
        `api/academic-calendars`,
        `?${filterParams}`
      );

      this.dataLoaded = true;
      if (response.data.length > 0) {
        if (response.data[0].is_absent == 0) {
          this.is_presence_today = false;
          this.updateTimeStatus = false;
        }
      }
    },

    // form submit
    async formOnsubmitPresence() {
      // stop time
      this.updateTimeStatus = false;
      await this.onCapture();
      // let currentTime = new Date().toTimeString();

      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.form.late = 0;
      this.form.is_presence = 2;

      let mergeTime = this.date + " " + this.time;
      let currentDate = new Date().toISOString().substr(0, 10);
      let currentTime = new Date().toTimeString().substr(0, 8);
      this.form.date = currentDate + " " + currentTime;

      // if(currentTime >= this.presence_type_display.open_time && currentTime <= this.presence_type_display.close_time){
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = 0
      //   //("betul")
      // }else{
      //   // this.form.presence_type_id = this.form.presence_type_id
      //   this.form.late = this.diff(this.presence_type_display.close_time, currentTime)
      //   //("salah disini")
      // }

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      // classrooms/{classroomId}/student-presences
      let response = await module.submit(formData, `api/student-presences`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        // this.error = validation.setValidationError(
        //   this.error,
        //   response.error.errors
        // );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.presence_today_status = true;
        this.absentTime = response.success.item.original.date.split(" ")[1];
        this.presence_today = response.success.item.original;
        this.$root.$emit("refreshPresenceCalendar");
        this.is_presence = 2;
      }

    },

    setTime() {
      let ct = new Date();
      this.time =
        this.zeroPadding(ct.getHours(), 2) +
        ":" +
        this.zeroPadding(ct.getMinutes(), 2) +
        ":" +
        this.zeroPadding(ct.getSeconds(), 2);
    },

    setDate() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      this.date = new Date().toLocaleDateString("id-ID", options);
    },

    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    updateTime() {
      if (this.updateTimeStatus == true) {
        setTimeout(() => {
          if (this.updateTimeStatus == true) {
            this.setTime();
            this.updateTime();
          }
        }, 1000);
      }
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.capture_status = true;
    },
    onStarted(stream) {
      //("On Started Event", stream);
    },
    onStopped(stream) {
      //("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      //("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      //("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //("On Camera Change Event", deviceId);
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1") {
          this.dashboard = true
        }
        if (access_right[a] == "2") {
          this.calendar = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    console.log('dashboard')
    this.updateTime();
    this.setDate();
    this.checkPresenceToday();
    this.checkIsPresenceToday();

    this.checkAccess()
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
};
</script>
