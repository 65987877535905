<template>
  <div v-if="my_classGroups.length > 0">
    <div class="card">
      <div class="card-body py-0 px-0">
        <b-tabs
          content-class="my-3"
          @input="inputTabs"
          v-if="userData.role_id == 1"
        >
          <template v-for="classGroup in my_classGroups">
            <b-tab :key="classGroup.id" :title="classGroup.name"></b-tab>
          </template>
        </b-tabs>

        <div class="d-flex justify-content-between align-items-center px-2">
          <!-- <h4
            class="font-weight-bolder text-dark ml-3"
            v-if="userData.role_id == 1"
          >
            Forum Kelompok Kelas {{ my_classGroups[orderTabActive].name }}
          </h4>
          <h4
            class="font-weight-bolder text-dark ml-3"
            v-if="userData.role_id == 2"
          >
            Forum Kelompok Kelas {{ my_classGroups.name }}
          </h4> -->
          <!-- <b-button
            variant="primary"
            @click="$bvModal.show('modal-class-group-conversation')"
          >
            Post
          </b-button> -->
        </div>

      <!-- <form @submit.stop.prevent="makeForum"> -->
        <div class="d-flex mt-2">
          <div class="col-md-11 col-sm-12 pt-1 pb-0">
            <div class="d-flex align-items-center">
                <twemoji-picker
                  :emojiData="emojiDataAll"
                  :emojiGroups="emojiGroups"
                  :skinsSelection="false"
                  :searchEmojisFeat="true"
                  :recentEmojisFeat="true"
                  :pickerAutoflip="false"
                  @emojiUnicodeAdded="emojiUnicodeAdded"
                  @emojiImgAdded="emojiImgAdded"
                  searchEmojiPlaceholder="Search here."
                  searchEmojiNotFound="Emojis not found."
                  isLoadingLabel="Loading..."
                ></twemoji-picker>
                <v-textarea
                  :label="'Apa yang kamu pikirkan, '+userData.name+'?' "
                  auto-grow
                  outlined
                  no-resize
                  rows="1"
                  v-model="forum.message"
                ></v-textarea>
            </div>
            <!-- <twemoji-textarea 
              :emojiData="emojiDataAll" 
              :emojiGroups="emojiGroups" 
              componentColor="transparent"
              @enterKey="onEnterKey">
            </twemoji-textarea> -->
          </div>
          <div class="col-md-1 col-sm-12">
            <b-button 
              variant="primary"
              @click.stop.prevent="makeForum"
              :disabled="forum.message != ''"
            > Post </b-button>
          </div>
        </div>
      <!-- </form> -->

        <template v-for="(forum, i) in forums">
          <b-card
            no-body
            :key="i"
            class="
              card-custom
              gutter-b
              card-stretch
              shadow-none
              card-border
              mx-2
            "
          >
            <b-card-body class="pb-4 p-5">
              <v-hover v-slot="{ hover }">
                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <div class="d-flex align-items-center">
                      <div>
                        <b-img
                          width="43"
                          rounded="circle"
                          :src="forum.created_by_photo"
                          alt="Circle image"
                        ></b-img>
                      </div>
                      <div class="ml-2">
                        <span class="text-dark d-block">{{
                          forum.created_by_name
                        }}</span>
                        <span class="text-muted">
                          <MomentAgo
                            prefix="di buat"
                            suffix="yang lalu"
                            :date="forum.created_at"
                            lang="id"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="p-2 bd-highlight"
                    v-if="hover && userData.id == forum.created_by"
                  >
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none p-0"
                      :dropleft="true"
                      no-caret
                    >
                      <template #button-content>
                        <i class="fas fa-ellipsis-v fa-sm"></i>
                      </template>
                      <b-dropdown-item @click="handleEditForum(forum.id)"
                        >Edit</b-dropdown-item
                      >
                      <b-dropdown-item @click="deleteComment(forum.id)"
                        >Hapus</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
              </v-hover>

              <b-card-text class="mt-2">
                {{ forum.message }}
              </b-card-text>
              <div class="d-flex justify-content-start">
                <div class="col-md-1 px-0 pb-0 pt-2">
                  <div
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    v-if="forum.is_like == null"
                    @click="like(forum.id)"
                  >
                    <i class="fas fa-thumbs-up mr-1"></i>
                    <span>{{ forum.like }} Suka</span>
                  </div>
                  <div
                    class="d-flex align-items-center"
                    style="cursor: pointer"
                    v-if="forum.is_like != null"
                    @click="unlike(forum.id)"
                  >
                    <i class="fas fa-thumbs-up text-success mr-1"></i>
                    <span>{{ forum.like }} Suka</span>
                  </div>
                </div>
                <div class="col-md-3 pb-0 pt-2">
                  <div class="d-flex align-items-center">
                    <i class="fas fa-comment-alt mr-1"></i>
                    <span v-if="forum.children.length == 0"
                      >Belum ada Komentar</span
                    >
                    <span v-if="forum.children.length != 0"
                      >{{ forum.children.length }} Komentar</span
                    >
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-start">
                <div class="col-10">
                  <div class="wrapper-emoji">
                    <textarea
                      class="regular-input"
                      v-model="form.message"
                      placeholder="Kirim sebuah komentar"
                    ></textarea>

                    <emoji-picker @emoji="append" :search="search">
                      <div
                        class="emoji-invoker"
                        slot="emoji-invoker"
                        slot-scope="{ events: { click: clickEvent } }"
                        @click.stop="clickEvent"
                      >
                        <svg
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                          />
                        </svg>
                      </div>
                      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                        <!-- <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }"> -->
                        <div class="emoji-picker emoji-picker-position">
                          <div class="emoji-picker__search">
                            <input type="text" v-model="search" v-focus />
                          </div>
                          <div>
                            <div
                              v-for="(emojiGroup, category) in emojis"
                              :key="category"
                            >
                              <h5>{{ category }}</h5>
                              <div class="emojis">
                                <span
                                  v-for="(emoji, emojiName) in emojiGroup"
                                  :key="emojiName"
                                  @click="insert(emoji)"
                                  :title="emojiName"
                                  >{{ emoji }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </emoji-picker>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  @click="sendComment(forum.id, forum.created_by)"
                  :disabled="form.message == '' ? true : false"
                >
                  <i class="fas fa-paper-plane"></i> Kirim Komentar
                </b-button>
              </div>

              <h6>Komentar</h6>
            </b-card-body>

            <b-card-footer class="p-0">
              <template v-for="comment in forum.children">
                <v-hover v-slot="{ hover }" :key="comment.id">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-top
                      mt-3
                      ml-2
                    "
                  >
                    <div class="">
                      <b-img
                        width="43"
                        :src="comment.created_by_photo"
                        rounded="circle"
                      ></b-img>
                    </div>
                    <div class="ml-1">
                      <b-card no-body class="card card-custom shadow-none mx-2">
                        <b-card-body class="p-1">
                          <!-- <div class="d-flex justify-content-between"> -->
                          <span class="text-dark font-weight-bold d-block">{{
                            comment.created_by_name
                          }}</span>
                          <!-- </div> -->

                          <b-card-text
                            class="comment-message"
                            v-html="comment.message"
                          >
                          </b-card-text>
                        </b-card-body>
                      </b-card>
                      <div class="d-flex justify-content-start mt-1">
                        <span
                          v-if="comment.is_like == null"
                          @click="like(comment.id)"
                        >
                          <i
                            class="fas fa-thumbs-up mr-1"
                            style="cursor: pointer"
                          ></i>
                          {{ comment.like }} Suka
                        </span>
                        <span
                          v-if="comment.is_like != null"
                          @click="unlike(comment.id)"
                        >
                          <i
                            class="fas fa-thumbs-up text-primary mr-1"
                            style="cursor: pointer"
                          ></i>
                          {{ comment.like }} Suka
                        </span>
                        <span class="ml-1">~</span>
                        <span
                          ><MomentAgo
                            prefix=""
                            suffix="yang lalu"
                            :date="comment.created_at"
                            lang="id"
                        /></span>
                      </div>
                    </div>
                    <div
                      class="ml-2"
                      v-if="hover && userData.id == comment.created_by"
                    >
                      <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <i class="fas fa-ellipsis-v fa-sm"></i>
                        </template>
                        <b-dropdown-item @click="handleEditComment(comment.id)"
                          >Edit</b-dropdown-item
                        >
                        <b-dropdown-item @click="deleteComment(comment.id)"
                          >Hapus</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </v-hover>
              </template>
              <div class="d-flex justify-content-center align-items-center">
                <span
                  class="text-primary"
                  @click="showNextComment"
                  style="cursor: pointer"
                  v-if="forum.children.length != 0"
                >
                  Lihat lebih banyak komentar
                  <i class="fas fa-angle-down text-primary ml-1 mt-1"></i>
                </span>
              </div>
            </b-card-footer>
          </b-card>
        </template>
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="perPage != totalForum"
        >
          <span
            class="text-primary"
            @click="showNextForum"
            style="cursor: pointer"
          >
            Lihat lebih banyak kiriman
            <i class="fas fa-angle-down text-primary ml-1 mt-1"></i>
          </span>
        </div>
      </div>
    </div>
    <ModalClassGroupConversation
      @successAdd="successAddNewForum"
      @cancelAdd="$bvModal.hide('modal-class-group-conversation')"
      :classGroupId="filter.class_group_id"
      :purposeModal="purposeModal"
      :data="form"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import ModalClassGroupConversation from "@/view/components/general/ModalClassGroupConversation.vue";
import Post from "@/view/components/dashboard/Post.vue";
import MomentAgo from "@/view/components/general/MomentAgo.vue";
import api from "@/core/services/api.service.js";
import EmojiPicker from "vue-emoji-picker";

import { TwemojiTextarea } from '@kevinfaguiar/vue-twemoji-picker';
import { TwemojiPicker } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiDataAnimalsNature from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-animals-nature.json";
import EmojiDataFoodDrink from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-group-food-drink.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";

export default {
  components: {
    ModalClassGroupConversation,
    Post,
    MomentAgo,
    EmojiPicker,
    "twemoji-picker": TwemojiPicker,
    'twemoji-textarea': TwemojiTextarea
  },
  data() {
    return {
      search: "",
      // Pagination
      perPage: 1,
      currentPage: 1,
      totalRows: 1,
      commentSize: 2,
      model: null,
      my_classrooms: [],
      my_classGroups: [],
      orderTabActive: 0,
      // forum
      forums: [],
      routeForum: `api/class-group-conversations`,
      // filter
      filter: {
        class_group_id: "",
      },
      // form
      forum: {
        class_group_id: "",
        parent_id: "",
        user_id: "",
        message_to: "",
        message: "",
        like: "",
      },
      form: {
        class_group_id: "",
        parent_id: "",
        user_id: "",
        message_to: "",
        message: "",
        like: "",
      },
      error: {
        class_group_id: "",
        parent_id: "",
        user_id: "",
        message_to: "",
        message: "",
        like: "",
      },
      routeComment: "api/class-group-conversations",
      // other
      userData: getUser(),
      totalForum: "",
      purposeModal: "add",
    };
  },
  methods: {
    // forum
    emojiUnicodeAdded(emoticon){
      this.forum.message += emoticon
    },
    emojiImgAdded(evt){
    },
    onEnterKey(e) {
    },

    async makeForum() {
      this.forum.user_id = getUser().id;

      // if (this.routeComment == `api/class-group-conversations`) {
        if (getUser().role_id == 1) {
          this.forum.class_group_id =
            this.my_classGroups[this.orderTabActive].id;
        } else if (getUser().role_id == 2) {
          this.forum.class_group_id = this.my_classGroups.id;
        }
      // }

      // Make Request
      let response = await module.submit(
        this.forum,
        this.routeForum
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.getForum();
        this.routeComment = "api/class-group-conversations";
        this.forum.message = "";
      }
    },


    // comment
    append(emoji) {
      this.form.message += emoji;
    },
    inputTabs(evt) {
      this.orderTabActive = evt;
      this.filter.class_group_id = this.my_classGroups[this.orderTabActive].id;
      this.getForum();
    },
    async getMyClassGroup() {
      let filterParams = ``;
      let today = new Date().toISOString().substr(0, 10);
      if (getUser().role_id == 1) {
        let response = await module.paginate(
          `api/class-groups/available/my-class-groups/users/${getUser().id}`,
          `?now=${today}`
        );

        if (response.data.length !== 0) {
          this.my_classGroups = response.data;
          this.filter.class_group_id = response.data[0].id;
          this.getForum();
        }
      } else if (getUser().role_id == 2) {
        let response = await module.paginate(
          `api/class-groups/my-class-groups/users/${getUser().id}/students`,
          `?now=${today}`
        );
        this.my_classGroups = response.data;
        this.filter.class_group_id = response.data.id;
        this.getForum();
      }
    },
    async getForum() {
      let filterParams = `&class_group_id=${
        this.filter.class_group_id
      }&user_id=${getUser().id}`;
      let response = await module.paginate(
        `api/class-group-conversations`,
        `?page=${this.currentPage}&page_size=${this.perPage}&comment_size=${this.commentSize}${filterParams}`
      );

      this.forums = response.data;
      this.totalForum = response.meta.pagination.total;
    },
    showNextForum() {
      this.perPage = this.perPage + 1;
      this.getForum();
    },
    showNextComment() {
      this.commentSize = this.commentSize + 1;
      this.getForum();
    },
    successAddNewForum() {
      this.$bvModal.hide("modal-class-group-conversation");
      this.purposeModal = "add";
      this.getForum();
    },
    async sendComment(parent_id, message_to) {
      this.form.parent_id = parent_id;
      this.form.message_to = message_to;
      this.form.user_id = getUser().id;

      if (this.routeComment == `api/class-group-conversations`) {
        if (getUser().role_id == 1) {
          this.form.class_group_id =
            this.my_classGroups[this.orderTabActive].id;
        } else if (getUser().role_id == 2) {
          this.form.class_group_id = this.my_classGroups.id;
        }
      }

      // Make Request
      let response = await module.submitWithoutAlert(
        this.form,
        this.routeComment
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        this.getForum();
        this.routeComment = "api/class-group-conversations";
        this.form.message = "";
      }
    },
    like(id) {
      api
        .post(`api/class-group-conversations/${id}/users/${getUser().id}/like`)
        .then((response) => {
          this.getForum();
        })
        .catch((error) => {
          //("error like", error.response);
        });
    },
    unlike(id) {
      api
        .delete(
          `api/class-group-conversations/${id}/users/${getUser().id}/unlike`
        )
        .then((response) => {
          this.getForum();
        })
        .catch((error) => {
          //("error unlike", error.response);
        });
    },
    async deleteComment(id) {
      // Delete Data
      let result = await module.delete("api/class-group-conversations/" + id);
      // If Deleted
      if (result) {
        this.getForum();
      }
    },
    async handleEditComment(id) {
      this.form = await module.get("api/class-group-conversations/" + id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        // this.$router.push('/masters/formats')
      } else {
        this.form["_method"] = "put";
        this.scrollToForm({ behavior: "smooth" });
        this.routeComment = `api/class-group-conversations/${id}`;
      }
    },
    async handleEditForum(id) {
      this.forum = await module.get("api/class-group-conversations/" + id);
      // If Data Not Found
      if (this.forum == null) {
        // Redirect To List
        // this.$router.push('/masters/formats')
      } else {
        this.forum["_method"] = "put";
        this.scrollToForum({ behavior: "smooth" });
        // this.$bvModal.show("modal-class-group-conversation");
        this.purposeModal = "update";
        this.routeForum = `api/class-group-conversations/${this.forum.id}`
      }
    },
    scrollToForum(options) {
      let el = this.$el.getElementsByClassName("tabs")[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
    scrollToForm(options) {
      let el = this.$el.getElementsByClassName("rounded-circle")[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
  },
  mounted() {
    this.getMyClassGroup();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
    EmojiDataAnimalsNature() {
      return EmojiDataAnimalsNature;
    },
    EmojiDataFoodDrink() {
      return EmojiDataFoodDrink;
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style>
/* ::placeholder {
  color: red;
  opacity: 1; 
} */

/* forum emoji */
#btn-emoji-default .emoji {
  margin-left: 2px !important;
}
/* forum emoji */

.btn-comment-forum {
  cursor: pointer;
  background-color: rgb(245, 245, 245);
  height: 20px;
  width: 15px;
  border-radius: 10px;
}

.wrapper-emoji {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 470%;
  height: 4rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  left: 450%;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

/* my style */
.emoji-picker-position {
  /* style="position: absolute; bottom: 70px; left: 550px;" */
  position: absolute;
  bottom: 60px;
  left: 550px;
}

/* mobile */
@media (max-width: 767.98px) {
  .regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 160%;
    height: 4rem;
    outline: none;
  }

  .emoji-invoker {
    position: absolute;
    top: 0.5rem;
    left: 140%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  .emoji-picker-position {
    position: absolute;
    bottom: 66px;
    left: 60px;
  }
}
</style>
