<template>
  <v-app>
    <div class="card p-3">
      <h4>Jadwal Saya</h4>
      <v-row class="fill-height">
        <v-col md="2">
          <div class="row">
            <div class="col-12 my-2 ml-1">
              <treeselect
                v-model="filter.semester_id"
                :multiple="false"
                placeholder="Saring Semester"
                :options="semesters"
                @input="filterBySemester"
              />
            </div>
          </div>
          <div class="row no-gutters mt-2 ml-2">
            <b-form-checkbox
              name="check-button"
              v-model="checked"
              @input="chekAllRoom"
            >
              Semua Ruang
            </b-form-checkbox>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox
                v-for="room in rooms"
                v-model="filter.room_id_multiple"
                :key="room.id"
                :value="room.id"
                :aria-describedby="ariaDescribedby"
                name="flavour-3a"
                @change="checkRoom"
              >
                {{ room.building_name + "-" + room.name }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </v-col>
        <v-col>
          <v-sheet height="64">
            <div class="row no-gutters px-2 py-2 align-items-center">
              <div class="col-1">
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hari ini
                </v-btn>
              </div>
              <div class="col-4 mx-4">
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <span v-if="$refs.calendar" class="mr-3 h5 font-weight-normal">
                  {{ $refs.calendar.title }}
                </span>
              </div>
              <!-- <div class="col-2 offset-md-4">
              <v-menu bottom right class="ml-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                  >
                    <span>{{ semester_active }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="(semester, i) in semesters">
                    <v-list-item
                      @click="filterBySemester(semester.id, semester.label)"
                      :key="i"
                    >
                      <v-list-item-title>{{
                        semester.label
                      }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </div> -->
            </div>
            <!-- <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hari ini
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="mr-3">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-menu bottom right class="ml-1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                >
                  <span>{{ semester_active }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(semester, i) in semesters">
                  <v-list-item
                    @click="filterBySemester(semester.id, semester.label)"
                    :key="i"
                  >
                    <v-list-item-title>{{ semester.label }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-toolbar> -->
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              locale="id-ID"
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              type="week"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="calendarChange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span class="d-block font-weight-bold">
                    <i
                      class="fas fa-circle mr-2"
                      :style="`color:${selectedEvent.color}`"
                    ></i>
                    {{ selectedEvent.place }}
                  </span>
                  <span class="d-block my-1 font-weight-bold">
                    <i
                      class="fas fa-user mr-2"
                      :style="`color:${selectedEvent.color}`"
                    ></i>
                    {{ selectedEvent.teacher_name }}
                  </span>
                  <span class="d-block my-1 font-weight-bold">
                    {{
                      new Date(selectedEvent.start).toLocaleDateString(
                        "en-GB"
                      ) +
                      " " +
                      selectedEvent.start_time +
                      "-" +
                      selectedEvent.end_time
                    }}
                  </span>
                  <span class="d-block my-1">
                    {{ selectedEvent.subject_name }}
                  </span>
                  <span class="d-block my-1">
                    kode: {{ selectedEvent.subject_name }}
                  </span>
                  <span class="d-block my-1">
                    sks: {{ selectedEvent.subject_sks }}
                  </span>
                  <span class="d-block my-1">
                    kapasitas: {{ selectedEvent.capacity }}
                  </span>
                  <span class="d-block my-1">
                    program studi: {{ selectedEvent.study_program_name }}
                  </span>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    orderMonth: Number,
  },
  data() {
    return {
      selected: ["checkAll"],
      checked: true,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Bulan",
        week: "Minggu",
        day: "Hari",
        "4day": "4 Days",
      },
      selectedEvent: {
        start_time: new Date(),
        end_time: new Date(),
        start: new Date(),
        end: new Date(),
      },
      selectedElement: null,
      selectedOpen: false,
      events: [],
      events_form: {
        id: "",
        name: "",
        start: "",
        end: "",
        color: "",
        parent_id: "",
        description: "",
      },
      currentYear: new Date().getFullYear(),
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      // filter
      filter: {
        semester_id: JSON.parse(window.localStorage.getItem("semester_active"))
          .id,
        room_id_multiple: [],
      },
      roomIdCollection: [],
      semester_active: JSON.parse(
        window.localStorage.getItem("semester_active")
      ).name,
      semesters: [],
      rooms: [],
      items: [],
      perPage: 500,
      currentPage: 1,
      totalRows: 1,
      set_holiday: {
        name: "Libur",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: new Date().toISOString().substr(0, 10),
        school_year_id: "",
        is_school: 0,
        is_absent: 0,
        description: "",
      },
      school_years: [],
      school_year_actv: "",
      // other
      dataLoaded: false,
      // access
      btnAccess: false,
    };
  },
  methods: {
    calendarChange({ start, end }) {
      console.log("start", start);
      console.log("end", end);
      let arr = [],
        date = new Date(start.date);
      console.log("date monday", date);
      for (let i = 1; i <= 8; i++) {
        i == 1
          ? arr.push(new Date(date.setDate(date.getDate())))
          : arr.push(new Date(date.setDate(date.getDate() + 1)));
      }

      this.events = [];
      // let today = new Date().toISOString().substr(0, 10);
      // let response = await module.paginate(
      //   `api/schedule-attributes-no-pagination`
      // );
      // this.items = response.data;
      let startvar, endvar;
      for (let a = 0; a < this.items.length; a++) {
        for (let b = 0; b < this.items[a].times.length; b++) {
          for (let j = 0; j < arr.length; j++) {
            if (
              this.items[a].day_name ==
              new Date(arr[j]).toLocaleString("id-ID", { weekday: "long" })
            ) {
              let schedules = this.items[a].schedules;
              let times = this.items[a].times[b];
              let teachers = this.items[a].teachers;
              let teacher_name = "";
              teacher_name = teachers.map((item) => {
                return item.name;
              });

              startvar = new Date(arr[j]);
              startvar.setHours(times.start_time.split(":")[0]);
              startvar.setMinutes(times.start_time.split(":")[1]);
              endvar = new Date(arr[j]);
              endvar.setHours(times.end_time.split(":")[0]);
              endvar.setMinutes(times.end_time.split(":")[1]);

              this.events_form.id = this.items[a].id;
              this.events_form.name = `[${schedules.subject_code}] ${schedules.subject_name} (${schedules.subject_sks} sks)`;
              (this.events_form.start = startvar),
                (this.events_form.end = endvar),
                (this.events_form.weekdays = 1);
              (this.events_form.color =
                this.colors[this.random(0, this.colors.length - 1)]),
                (this.events_form.timed = true);
              //
              this.events_form.start_time = times.start_time;
              this.events_form.end_time = times.end_time;
              this.events_form.end_time = times.end_time;
              this.events_form.teacher_name = teacher_name.toString();
              this.events_form.building_name = this.items[a].building_name;
              this.events_form.room_name = this.items[a].room_name;
              this.events_form.place =
                this.items[a].building_name + "-" + this.items[a].room_name;
              this.events_form.subject_name = schedules.subject_name;
              this.events_form.subject_code = schedules.subject_code;
              this.events_form.subject_sks = schedules.subject_sks;
              this.events_form.capacity = schedules.capacity;
              this.events_form.study_program_name =
                schedules.study_program_name;

              // for(let c = 0; c < teachers.length; c++){

              // }

              // const usersByLikes = myUsers.map(item => {
              //   const container = {};

              //   container[item.name] = item.likes;
              //   container.age = item.name.length * 10;

              //   return container;
              // })

              let clone = { ...this.events_form };
              this.events.push(clone);
            }
          }
        }
      }
    },
    async getSchedules() {
      let arr = [],
        date = this.getMonday(new Date());
      console.log("date monday", date);
      for (let i = 1; i <= 8; i++) {
        i == 1
          ? arr.push(new Date(date.setDate(date.getDate())))
          : arr.push(new Date(date.setDate(date.getDate() + 1)));
      }

      console.log("arr", arr);
      this.events = [];
      // let today = new Date().toISOString().substr(0, 10);
      let filterParams = `semester_id=${
        this.filter.semester_id
      }&room_id_multiple=${this.filter.room_id_multiple.toString()}&teacher_id=${
        getUser().id
      }`;
      let response = await module.paginate(
        `api/schedule-attributes-no-pagination-dashboard?${filterParams}`
      );
      this.items = response.data;
      let start, end;
      for (let a = 0; a < this.items.length; a++) {
        for (let b = 0; b < this.items[a].times.length; b++) {
          for (let j = 0; j < arr.length; j++) {
            if (
              this.items[a].day_name ==
              new Date(arr[j]).toLocaleString("id-ID", { weekday: "long" })
            ) {
              let schedules = this.items[a].schedules;
              let times = this.items[a].times[b];
              let teachers = this.items[a].teachers;
              let teacher_name = "";
              teacher_name = teachers.map((item) => {
                return item.name;
              });

              start = new Date(arr[j]);
              start.setHours(times.start_time.split(":")[0]);
              start.setMinutes(times.start_time.split(":")[1]);
              end = new Date(arr[j]);
              end.setHours(times.end_time.split(":")[0]);
              end.setMinutes(times.end_time.split(":")[1]);

              this.events_form.id = this.items[a].id;
              this.events_form.name = `[${schedules.subject_code}] ${schedules.subject_name} (${schedules.subject_sks} sks)`;
              (this.events_form.start = start),
                (this.events_form.end = end),
                (this.events_form.weekdays = 1);
              (this.events_form.color =
                this.colors[this.random(0, this.colors.length - 1)]),
                (this.events_form.timed = true);
              //
              this.events_form.start_time = times.start_time;
              this.events_form.end_time = times.end_time;
              this.events_form.end_time = times.end_time;
              this.events_form.teacher_name = teacher_name.toString();
              this.events_form.building_name = this.items[a].building_name;
              this.events_form.room_name = this.items[a].room_name;
              this.events_form.place =
                this.items[a].building_name + "-" + this.items[a].room_name;
              this.events_form.subject_name = schedules.subject_name;
              this.events_form.subject_code = schedules.subject_code;
              this.events_form.subject_sks = schedules.subject_sks;
              this.events_form.capacity = schedules.capacity;
              this.events_form.study_program_name =
                schedules.study_program_name;

              // for(let c = 0; c < teachers.length; c++){

              // }

              // const usersByLikes = myUsers.map(item => {
              //   const container = {};

              //   container[item.name] = item.likes;
              //   container.age = item.name.length * 10;

              //   return container;
              // })

              let clone = { ...this.events_form };
              this.events.push(clone);
            }
          }
        }
      }
    },
    chekAllRoom(evt) {
      if (this.checked == true) {
        console.log("true");
        // this.selected = [];
        this.filter.room_id_multiple = this.roomIdCollection;
      } else {
        console.log("false");
        this.filter.room_id_multiple = [];
      }

      this.getSchedules();
    },
    checkRoom(evt) {
      console.log("evt", evt);
      if (evt.length != this.roomIdCollection.length) {
        this.checked = false;
      } else {
        this.checked = true;
      }

      this.getSchedules();
    },
    filterBySemester(evt) {
      if (typeof evt == "undefined") {
        this.filter.semester_id = "";
      }
      this.pagination();
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    setActivity(evt) {
      // //("evt", evt);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      console.log("evt", event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() - 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() - 1}-${a.getDate()}`;
    },
    async getRoom() {
      this.rooms = await module.get("api/rooms?page_size=100");
      if (this.rooms != null) {
        for (let a = 0; a < this.rooms.length; a++) {
          this.filter.room_id_multiple.push(this.rooms[a].id);
          this.roomIdCollection.push(this.rooms[a].id);

          if (a + 1 == this.rooms.length) {
            this.getSchedules();

            console.log("filter room", this.filter.room_id_multiple);
            console.log("room id collect", this.roomIdCollection);
          }
        }
      } else {
        this.getSchedules();
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Saring Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8008") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Ruang", route: "/masters/rooms/calendar" },
    // ]);

    this.$refs.calendar.checkChange();
    if (this.$refs.calendar.title.split(" ")[0] == "July") {
      this.$refs.calendar.prev(6);
    }

    // this.getSchedules();
    this.checkAccess();
    this.getSemesterOption();
    this.getRoom();

    this.$root.$on("typeCalendar", (type) => {
      this.type = type;
      console.log("type now", this.type);
    });
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
};
</script>

<style>
</style>