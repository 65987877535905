<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h4 class="card-title font-weight-bolder text-dark">Agenda Besok</h4>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body px-2 py-0">
      <div id="itemList">
        <div v-for="(item, i) in classworkForList" :key="i">
          <div class="mb-2" :key="i">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div
                class="
                  btn btn-icon btn-clean btn-dropdown btn-lg
                  mr-1
                  pulse pulse-success
                "
              >
                <span
                  class="svg-icon svg-icon-xl svg-icon-danger"
                  v-b-tooltip.hover
                  title="Tugas"
                  :class="`svg-icon-info`"
                >
                  <inline-svg src="/media/svg/icons/Home/Book-open.svg" />
                </span>
                <span class="pulse-ring"></span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a
                  href="#"
                  class="
                    font-weight-bold
                    text-dark-75 text-hover-primary
                    font-size-lg
                    mb-1
                  "
                >
                  {{ item.subject_name }}
                  <span class="font-weight-normal"> - {{ item.building_name + " - " + item.room_name }} </span>
                  <div class="d-block">
                  <span class="text-muted font-weight-normal" v-for="(time, i) in item.times" :key="time.id">
                    {{time.start_time + "-" + time.end_time}}
                    <template v-if="i + 1 != item.times.length">
                      ,
                    </template>
                  </span>
                  </div>
                </a>
              </div>
              <!--end::Text-->
            </div>
          </div>
        </div>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        v-if="scheduleToday.length != 0"
        aria-controls="itemList"
        class="mt-4"
      ></b-pagination>
      <!-- style="position: absolute; bottom: 0;" -->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "widget-2",
  data() {
    return {
      list: [
        {
          text0: "media/stock-600x400/img-17.jpg",
          text1: "Cup & Green",
          text2: "Local, clean & environmental",
          text3: "CoreAd",
          text4: "24,900",
        },
        {
          text0: "media/stock-600x400/img-10.jpg",
          text1: "Yellow Background",
          text2: "Strong abstract concept",
          text3: "KeenThemes",
          text4: "70,380",
        },
        {
          text0: "media/stock-600x400/img-17.jpg",
          text1: "Nike & Blue",
          text2: "Footwear overalls",
          text3: "Invision Inc.",
          text4: "7,200",
        },
        {
          text0: "media/stock-600x400/img-9.jpg",
          text1: "Desserts platter",
          text2: "Food trends & reviews",
          text3: "Figma Studio",
          text4: "36,450",
        },
        {
          text0: "media/stock-600x400/img-12.jpg",
          text1: "Cup & Green",
          text2: "Local, clean & environmental",
          text3: "CoreAd",
          text4: "23,900",
        },
      ],
      scheduleToday: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
    };
  },
  components: {
    Dropdown2,
  },
  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    async getSchedulesToday() {
      this.events = [];

      let tomorrow = new Date().toISOString().substr(0, 10);

      let response = await module.getSchedules(
        `api/schedule-attributes-no-pagination-dashboard?teacher_id${
          getUser().id
        }`,
        `&tomorrow=${tomorrow}`
      );
      this.scheduleToday = response.data.data;
      console.log("scheduleToday", this.scheduleToday);

      // for(let a = 0; a < response.data.assignment_classworks.length; a++){
      //   this.scheduleToday.push(response.data)
      // }

      // for(let b = 0; b < response.data.classwork_quizzes.length; b++){
      //   this.scheduleToday.push(response.data.classwork_quizzes[b])
      // }

      // for(let c = 0; c < response.data.classwork_schedules.length; c++){
      //   this.scheduleToday.push(response.data.classwork_schedules[c])
      // }

      // setTimeout(() => {
      //   this.scheduleToday.sort((a, b) => (a.start_date > b.start_date ? 1 : -1))
      //   this.totalRows = this.scheduleToday.length
      // }, 3000);
    },

    //   itemsForList() {
    //   return this.data.slice(
    //     (this.currentPage - 1) * this.perPage,
    //     this.currentPage * this.perPage,
    //   );
    // }
  },
  mounted() {
    this.getSchedulesToday();
  },
  computed: {
    classworkForList() {
      return this.scheduleToday.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
};
</script>
