<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Pengumuman</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body px-2 py-0">
      <template v-for="(item, i) in announcements">
        <div class="mb-2" :key="i">
        <div class="d-flex align-items-center" @click="showModal(item.id)">
            <!--begin::Symbol-->
            <div
              class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-success"
            >
                <span
                  class="svg-icon svg-icon-xl svg-icon-primary"
                  v-b-tooltip.hover
                  title="Pengumuman"
                  :class="`svg-icon-primary`"
                >
                  <inline-svg
                    src="/media/svg/icons/Code/Info-circle.svg"
                  />
                </span>
              <span class="pulse-ring"></span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <template >
                <a
                  href="#"
                  class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{item.title}}
                </a>
                <span class="font-weight-normal">
                  {{item.content.substr(0, 30) + '...'}}
                </span>
                </template>
            </div>
            <!--end::Text-->
          </div>
        </div>
      </template>
      <b-pagination
        v-if="announcements.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </div>
    <!--end::Body-->

    <!--Start::Modal Announcement -->
      <b-modal ref="modal-announcement-show" size="lg" hide-footer :title="detailAnnouncement.title">
      <div class="d-block text-center">
        <!-- <h3 class="mb-4">{{detailAnnouncement.title}}</h3> -->
        <img v-if="detailAnnouncement.image" :src="detailAnnouncement.image" width="400" class="mb-10">
        <div class="mt-10" v-html="detailAnnouncement.content"></div>
      </div>
      <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
    </b-modal>
      <!--End::Modal Announcement -->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "widget-2",
  data() {
    return {
      list: [
        {
          text0: "media/stock-600x400/img-17.jpg",
          text1: "Cup & Green",
          text2: "Local, clean & environmental",
          text3: "CoreAd",
          text4: "24,900"
        },
        {
          text0: "media/stock-600x400/img-10.jpg",
          text1: "Yellow Background",
          text2: "Strong abstract concept",
          text3: "KeenThemes",
          text4: "70,380"
        },
        {
          text0: "media/stock-600x400/img-17.jpg",
          text1: "Nike & Blue",
          text2: "Footwear overalls",
          text3: "Invision Inc.",
          text4: "7,200"
        },
        {
          text0: "media/stock-600x400/img-9.jpg",
          text1: "Desserts platter",
          text2: "Food trends & reviews",
          text3: "Figma Studio",
          text4: "36,450"
        },
        {
          text0: "media/stock-600x400/img-12.jpg",
          text1: "Cup & Green",
          text2: "Local, clean & environmental",
          text3: "CoreAd",
          text4: "23,900"
        }
      ],
      announcements: [],
      detailAnnouncement: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
    };
  },
  components: {
    Dropdown2
  },
  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.getAnnouncement();
    },

    async getAnnouncement() {
      let filterParams = ``;
      let response = await module.paginate(
        "api/announcements/get-shows",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.announcements = response.data;
      // //('announcement', this.announcements)
    },
    async showModal(id) {
     this.detailAnnouncement = await module.get('api/announcements/' + id)
        //("detail announcements", this.detailAnnouncement)
        this.$refs['modal-announcement-show'].show()
    },
    hideModal() {
        this.$refs['modal-announcement-show'].hide()
    },
  },
  mounted(){
    this.getAnnouncement()
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
