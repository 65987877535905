<template>
  <div>
    <b-modal
      id="modal-absent"
      size="lg"
      title="Atur Jadwal Mingguan"
      body-class="pt-0"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12">
          <form>
            <b-form-group
              id="input-group-notes"
              label="Keterangan"
              label-for="input-notes"
            >
              <b-form-textarea
                id="input-notes"
                v-model="form.notes"
                placeholder="Keterangan"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.notes }}</small>
            </b-form-group>
          </form>
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <b-button
                type="submit"
                @click.stop.prevent="formValidation()"
                variant="primary"
                >Simpan</b-button
              >
              <b-button
                type="button"
                class="ml-2"
                variant="default"
                @click="cancelAbsent"
              >
                Batal
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    course: Object,
    data: Object,
    route: String,
    purpose: String,
    classroomId: String,
    secondPurpose: String
  },

  components: {},
  data() {
    return {
      courseData: [],
      // form
      form: {
        student_id: getUser().id,
        presence_type_id: "",
        date: "",
        photo: "",
        late: "",
        is_presence: 1,
        notes: "",
        classroom_id: ""
      },
      error: {
        student_id: "",
        presence_type_id: "",
        date: "",
        photo: "",
        late: "",
        is_presence: "",
        notes: "",
        classroom_id: ""
      },
    };
  },

  methods: {
    setData() {
      //   this.form.course_id = this.course.id;
      //   this.courseData = this.course;
      if(this.purpose != 'add' || this.purpose != 'add-classroom-absent'){
        //("purpose", this.purpose)
        this.form = this.data
      }
    },
    formValidation(){
        if(this.form.notes == ''){
            this.error.notes = 'Kolom keterangan tidak boleh kosong'
        }else{
            this.formOnsubmit()
        }
    },
    async formOnsubmit() {

        if(this.purpose == 'add' || this.purpose == 'add-classroom-absent'){
          let date = new Date().toISOString().substr(0, 10)
          let time = new Date().toTimeString().substr(0, 8)
  
          this.form.date = date + " " + time
          this.form.is_presence = 1
        }
        if(this.secondPurpose == 'PresenceClassroom'){
          this.form.classroom_id = this.classroomId
          //('absent classroom =======')
        }

        this.form.student_id = getUser().id
      // Make Request
      let response = await module.submit(
        this.form,
        this.route
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.successAbsent(response);
        this.resetFromOnNew()
      }
    },
    cancelAbsent() {
      this.$emit("cancelAbsent");
      this.resetErrorOnNew();
      //   this.resetFromOnNew()
    },
    successAbsent(response) {
      this.$emit("successAbsent", response);
      this.resetErrorOnNew();
      this.resetFromOnNew();
    },
    resetErrorOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.error)) {
        self.error[key] = "";
      }
    },
    resetFromOnNew() {
      let self = this;
      for (const [key, value] of Object.entries(this.form)) {
        self.form[key] = "";
      }
    },
  },
  mounted(){
      this.setData()
  },
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      //("Presence changed: ", newVal, " | was: ", oldVal);
        this.setData()
    },
  },
};
</script>

<style>
</style>